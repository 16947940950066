import React, { useEffect, useRef } from 'react';
import Layout from '../components/layout';
import WebinarRegistrationPopup from '../components/Popup/webinar-registration-popup';
import SEO from "../components/seo";
import IPProtectionImg from '../images/ip-protection-for.png';
import '../styles/webinar-page.css';

import { getMonthName, getDayOfMonth, getGTMOffsetStr, getTimeIn12Hours } from '../utils/utils';
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const WebinarHowToNavigate = () => {
  const WEBINAR_TITLE = "2025 Inventor's Guide: Navigating the Patent Process from Idea to Approval";
  const seo = {
    metaDesc: `${WEBINAR_TITLE}`
  }
  const WEBINAR_HOW_TO_NAVIGATE = "https://webinarkit.com/webinar/registration/oneclick/67b4e7c1644ccf89540fe3fd?date=jit_15";
  const childRef = useRef();
  const openPopup = () => {
    //
    childRef.current?.open(WEBINAR_HOW_TO_NAVIGATE);
  }
  //
  useEffect(() => {
    document?.body.classList.add('no-home');
    //
  }, []);

  return (
    <Layout activeMenu={'webinar'}>
      <SEO title={`Webinar ${WEBINAR_TITLE}`} canonical='/webinar-how-to-navigate-the-patenting-journey' seo={seo} />

      <div class="sub-banner" style={{ height: 'auto', backgroundColor: '#0C6873' }}>
        <div class="container py-5">
          <div class="text-center">
            <h1 className='text-white'>{WEBINAR_TITLE}
            </h1>
          </div>
        </div>
      </div>

      {/* <!-- Rsvp start --> */}
      <div class="rsvp content-area-2" id="rsvp">
        <div class="container px-5">
          <div className='px-5'>
            <div className=''>
              <div className='row'>
                <div class=" text-center col-md-3 wow fadeInUp delay-04s">
                  <div className='circular_image' style={{ width: '8rem', height: '8rem' }}>
                    <img style={{ display: 'block', objectFit: 'cover', width: '8rem' }} src="https://webinarkituploads.s3.us-east-2.amazonaws.com/user%2F6747df2bfba398dfcc5f14e8%2Ftranbaov2.png" alt='An AI-Powered Patent Drafting Solution'></img>
                  </div>
                  <p>
                    <small>Presented by:</small>
                  </p>
                  <p>
                    <strong>Bao Tran</strong>
                  </p>
                  <p>
                    Bao Tran is a patent attorney and the founder of the Inventiv Foundation and PatentPC
                  </p>
                  
                </div>
                <div class="col-md-6 wow fadeInUp delay-04s">
                  <div className='my-element'>
                    <h3 className='bold text-center'>Here's what you'll learn:</h3>
                    <div className='d-flex justify-content-start my-2'>
                      <h5 className='main-clr'>✓</h5>
                      <h5 className='ms-3'>
                        The key steps in the patenting journey from inventor Turner's perspective.</h5>
                    </div>

                    <div className='d-flex justify-content-start my-2'>
                      <h5 className='main-clr'>✓</h5>
                      <h5 className='ms-3'>Common challenges inventors face and how to overcome them.</h5>
                    </div>

                    <div className='d-flex justify-content-start my-2'>
                      <h5 className='main-clr'>✓</h5>
                      <h5 className='ms-3'>Strategies to protect your innovation and maximize its value.</h5>
                    </div>

                    <div className='d-flex justify-content-start my-2'>
                      <h5 className='main-clr'>✓</h5>
                      <h5 className='ms-3'>Insider tips on working with patent attorneys and navigating the application process.</h5>
                    </div>
                    <div>
                      <a href="#" onClick={e => { e.preventDefault(); openPopup() }} class="btn btn-default my-3" style={{ width: '100%' }}>Watch Webinar Now</a>
                    </div>
                  </div>
                </div>
                <div class=" text-center col-md-3 wow fadeInUp delay-04s">
                  <div class="card border-0 shadow">
                    <h5 class="card-header bg-primary text-white text-uppercase"><strong>{ getMonthName() }</strong></h5>
                    <div class="card-body">
                      <h5 class="card-title" style={{ fontSize: '60px'}}><strong>{ getDayOfMonth() }</strong></h5>
                    </div>
                  </div>
                  <p className='mt-5'  style={{ fontSize: '24px'}}>
                  <i class="fa fa-clock-o"></i> <span>  {getTimeIn12Hours()} { getGTMOffsetStr() }</span>
                  </p>
                  <h5 className='bold mt-2'>Instant watch session available. Join now!</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WebinarRegistrationPopup ref={childRef}></WebinarRegistrationPopup>
      {/* <!-- Rsvp end --> */}
    </Layout>
  )
};

export default WebinarHowToNavigate;